<template>
    <div>
        <div class="cls-map-button-bar">
            <div class="cls-map-button" :class="{ 'cls-focus': v_if_circle_fly }" @click="toggleCircle"
                v-show="showBtnCircle">
                <span class="iconfont">&#xe69e;</span>
                <p>盘旋</p>
            </div>
            <div class="cls-map-button" :class="{ 'cls-focus': v_if_check_house }" @click="onCheckHouse"
                v-show="showBtnHouse">查户</div>
            <div class="cls-map-button" :class="{ 'cls-focus': v_if_check_hu }" @click="onToggleHu" v-show="showBtnHu">
                <span class="iconfont">&#xe69d;</span>
                <p>标户</p>
            </div>
            <!-- <div class="cls-map-button" @click="onchangeMap2D" v-show="v_map_change_show">
                <span class="iconfont">&#xe6a9;</span>
                <p>换图</p>
            </div> -->
        </div>

        <dot-pop :name="'住户信息'" class="cls-pop-pane" :x="v_hu_info.x" :y="v_hu_info.y" :z="v_hu_info.z"
            v-show="v_hu_pane_show" @closePane="closeHuPane">
            <div style="display: flex;flex-direction: column;height: 100%;overflow: hidden;">
                <el-row style="height: 40px;border-bottom: 1px solid rgba(225, 225, 225, 0.6);">
                    <el-col :span="18">住址:&nbsp;{{ v_hu_info.dz }}</el-col>
                    <el-col :span="6">住户:&nbsp;{{ v_hu_info.rkList.length+'人' }}</el-col>
                </el-row>
                <el-row style="flex: 1;overflow: auto;height: 100%;" class="scrollbar">
                    <el-col v-for="(item, index) in v_hu_info.rkList" :key="index" style="line-height: 30px;border-bottom: 1px dashed  rgba(225, 225, 225, 0.6);">
                        <el-row>姓名:&nbsp;{{ item.xm +'('+item.yhzgx+')' }}</el-row>
                        <el-row>性别:&nbsp;{{ item.xb }}</el-row>
                        <el-row>出生:&nbsp;{{
                                item.csrq.substring(0, 4) + '年' + item.csrq.substring(4, 6) + '月' + item.csrq.substring(6, 8) + '日'
                        }}</el-row>
                    </el-col>
                </el-row>
            </div>
        </dot-pop>
    </div>
</template>

<script>
import config from '@/config.js';
import { getDiShiZhouCodeByCunCode, getTwoBitDotNumber } from '@/util/common.js';
import { circleFly } from '@/util/common.js';
import { getLonLatArray, addPoint, addPolygonFunction, addLine, cleanMarkerListByType, getMakerById } from '@/util/drawShape.js';
import http from '@/util/http.js';
import dotPop from "@/components/dot-pop.vue"

var g_interval_fly = null;
var g_handler = null;
var g_focus_entity = null; //选中的地块

var g_hu_list = [];
var g_handler_hu = null;

export default {
    components: { dotPop },
    props: {
        showBtnCircle: {type: Boolean, default: false},
        showBtnHouse: {type: Boolean, default: false},
        showBtnHu: {type: Boolean, default: false},
        enableCircleFly: {type: Boolean, default: false},  //是否启用盘旋
        enableClickMapJmd: {type: Boolean, default: false} //是否启用地图上单击查户
    },
    watch: {
        showBtnHu: function (newVal, oldVal) {
            if (!newVal) {
                this.hideAllHu();
                this.v_if_check_hu = false;
            }
        }
    },
    data() {
        return {
            v_if_circle_fly: false,  //不旋转
            v_if_check_house: false, //是否开始捕捉鼠标事件
            v_if_check_hu: false,   //是否标注所有户点位  不标注

            v_map_change_show: false,
            v_hu_pane_show: false,  //是否显示户面板
            v_hu_pane_index: 0,     //0-家庭成员 1-地块信息
            v_hu_info: { xm: '', dz: '', lxdh: '', jzmj: '', x: 0, y: 0, z: 0, rkList: [], dkList: [] },
            g_xqid : localStorage.getItem('tenantCode'), //'420626107204';
            g_dsz_id : getDiShiZhouCodeByCunCode( localStorage.getItem('tenantCode'))
        }
    },
    mounted() {
        let pMountedTimer = window.setInterval(() => {
            if (window.parentMounted) {
                window.clearInterval(pMountedTimer)
                this.init();
            }
        }, 500);

    },
    methods: {
        init() {
            g_handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
            if(this.enableCircleFly){
                this.inputActionCircle();
            }
            if(this.enableClickMapJmd){
                this.inputActionHu();
            } 
            if(window.g_map_2d_yx_list.length>1 && g_map_type==2){
                console.log('g_map_type', g_map_type);
                this.v_map_change_show = true;
            }
        },

        //切换二维地图用
        onchangeMap2D(){
            g_map_2d_index++;
            g_map_2d_index = g_map_2d_index%g_map_2d_yx_list.length;

            for (var i = g_2d_imagery_arr.length - 1; i >= 0; i--) {
                if (g_2d_imagery_arr[i]) {
                    viewer.imageryLayers.remove(g_2d_imagery_arr[i]);
                    g_2d_imagery_arr.splice(i, 1);
                }
            }
            for (var i = 0; i < g_map_2d_yx_list.length; i++) {
                if(g_map_2d_index == i){
                    var _imagery = viewer.imageryLayers.addImageryProvider(new Cesium.SuperMapImageryProvider({
                        url: g_map_2d_yx_list[i].url
                    }));
                    g_2d_imagery_arr.push(_imagery);
                }
            }
        },

        //启动盘旋
        inputActionCircle(){
            g_handler.setInputAction(this.toggleCircle, Cesium.ScreenSpaceEventType.RIGHT_CLICK);
        },
        removeInputActionCircle(){
            if(g_handler){
                g_handler.removeInputAction(Cesium.ScreenSpaceEventType.RIGHT_CLICK);
            }
        },
        
        toggleCircle() {
            this.v_if_circle_fly = !this.v_if_circle_fly;
            if (this.v_if_circle_fly) {
                g_interval_fly = setInterval(circleFly, 50);
            } else {
                if (g_interval_fly) {
                    clearInterval(g_interval_fly);
                    g_interval_fly = null;
                }
            }
        },

        //启动查询户信息，在地图上单击
        inputActionHu(){
            g_handler.setInputAction(this.handlerActionBuilding, Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
        },
        removeInputActionHu(){
            if(g_handler){
                g_handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
            }  
        },
        handlerActionBuilding(movement){
            viewer.entities.removeById('identify-area');
            this.v_hu_pane_show = false;
            var position = viewer.scene.pickPosition(movement.position);
            var cartographic = Cesium.Cartographic.fromCartesian(position);
            var longitude = Cesium.Math.toDegrees(cartographic.longitude);
            var latitude = Cesium.Math.toDegrees(cartographic.latitude);
            var height = cartographic.height;
            console.log('点选房屋m--', longitude, latitude, height);
            this.requestHouse(longitude, latitude, height);
        },


        onToggleHu() {//显示或隐藏所有户的标注
            this.v_if_check_hu = !this.v_if_check_hu;
            viewer.entities.removeById('identify-area');
            this.v_hu_pane_show = false;
            if (this.v_if_check_hu) {
                g_hu_list = []
                this.requestAllHu();
                // this.removeInputActionHu();
            } else {
                this.hideAllHu();
                // this.inputActionHu();
            }
        },

        requestAllHu() {//请求全村的户信息
            console.log('--查询全村的户', this.g_xqid);
            var _str_filter = "SSCID= '" + this.g_xqid + "' and sfzh is not null ";
            var _sqlParameter = {
                "getFeatureMode": "SQL",
                "datasetNames": ["p" + this.g_dsz_id + ":t02_hu"],
                "queryParameter": {
                    "attributeFilter": _str_filter,
                    "fields": ["id", "bh", "hzxm", "sfzh", "zu", "hao", "dz", "lxdh", "hjzmj", "fwjs", "fwcs", "sfkz", "zp"]
                },
                "maxFeatures": 2000
            }
            http.requestIServerWithPage(_sqlParameter, 0, 2000, (result) => {
                console.log('--户查询回调--', result.totalCount);
                for (var [_index, _feature] of result.features.entries()) {
                    //this.v_hu_pane_show = true;
					
                    g_hu_list.push({
                        id: _index,
                        sfzh: _feature.fieldValues[3],
                        xm: _feature.fieldValues[2],
                        dz: _feature.fieldValues[6].replace('湖北省保康县',''),
                        lxdh: _feature.fieldValues[7],
                        jzmj: getTwoBitDotNumber(_feature.fieldValues[8]),
                        x: _feature.geometry.center.x,
                        y: _feature.geometry.center.y,
                        z: this.getZ(), //50,  要统一用一个高度
                        rkList: [],
                        dkList: []
                    });
                }
                this.showAllHu();
            });
        },

        showAllHu() {
            for (var _item of g_hu_list) {
                addPoint('hu', _item.id, '', [_item.x, _item.y], null, { z: this.getZ(), image: require('@/assets/marker/m_person.png'), width: 26, height: 30 });
            }
            
            g_handler_hu = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
            g_handler_hu.setInputAction((movement) => {
                var pick = viewer.scene.pick(movement.position);
                if (Cesium.defined(pick)) {
                    if (pick.id) {
                        //选中
                        var _entity = pick.id;
                        
                        viewer.entities.removeById('identify-area');
                        cleanMarkerListByType('hdk');

                        if (_entity.ptype == 'hu') {
                            var _index = _entity.data.id;
                            this.v_hu_info = g_hu_list[_index];
                            console.log(this.v_hu_info);
                            //用于关掉其他户信息窗体，暂时这样写
                            // if(document.getElementsByClassName('win-pop cls-pop-pane')[0]){
                            //     document.getElementsByClassName('win-pop cls-pop-pane')[0].style.display = 'none';
                            // }
                            this.v_hu_pane_show = true;
                            var _hid = this.v_hu_info.sfzh;
                            if (_hid) {
                                this.requestRkList(_hid);
                                // this.requestDkList(_hid);
                            } else {
                                this.v_hu_pane_show = false;
                                this.inputEmptyHuInfo(0, 0);
                            }
                        }
                    }
                }
            }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
        },

        hideAllHu() {
            cleanMarkerListByType('hu');
            this.v_hu_pane_show = false;
            if (g_handler_hu) {
                g_handler_hu.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
                g_handler_hu = g_handler_hu && g_handler_hu.destroy();
            }
        },

        onCheckHouse: function () {
            viewer.entities.removeById('identify-area');
            cleanMarkerListByType('hdk');
            this.v_if_check_house = !this.v_if_check_house;
            if (this.v_if_check_house) {
                this.v_hu_pane_show = false;
                viewer._element.style.cursor = "crosshair";
                this.removeInputActionHu();
                g_handler.setInputAction(this.handlerAction, Cesium.ScreenSpaceEventType.LEFT_CLICK);

            } else {
                viewer._element.style.cursor = 'pointer';
                g_handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
                this.inputActionHu();
            }

        },

        handlerAction: function (movement) {

            var position = viewer.scene.pickPosition(movement.position);
            var cartographic = Cesium.Cartographic.fromCartesian(position);
            var longitude = Cesium.Math.toDegrees(cartographic.longitude);
            var latitude = Cesium.Math.toDegrees(cartographic.latitude);
            var height = cartographic.height;
            console.log('点选房屋--', longitude, latitude, height);
            this.v_if_check_house = false;
            g_handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
            viewer._element.style.cursor = 'pointer';

            viewer.entities.removeById('identify-area');
            cleanMarkerListByType('hdk');

            this.requestHouse(longitude, latitude, height);
        },

        requestHouse: function (x, y, z) {
            var _sqlParameter = {
                "getFeatureMode": "SPATIAL",
                "datasetNames": ["p" + this.g_dsz_id + ':t02_jmd'],//////****临时用的图层 */
                'spatialQueryMode': "INTERSECT",
                "queryParameter": { fields: ["smid", "sfzh", "sscid"] },
                'hasGeometry': true,
                'geometry': {
                    'parts': [1],
                    'type': "POINT",
                    'points': [{ 'id': "SuperMap.Geometry_12", 'bounds': null, 'SRID': null, 'x': x, 'y': y, 'tag': null, 'type': "Point", 'geometryType': "Point" }]
                }
            }

            http.requestIServerWithPage(_sqlParameter, 0, 9, (result) => {
                console.log('--居民地空间查询回调--', result.totalCount);
                if (result.featureCount > 0) {
                    var _feature = result.features[0];
                    this.addClapFeature(_feature);
                    var _hid = _feature.fieldValues[1];
                    if (_hid) {
                        this.requestHuInfoByHid(_hid, x, y, z);
                        this.requestRkList(_hid);
                        // this.requestDkList(_hid);
                    } else {
                        this.v_hu_pane_show = true;
                        this.inputEmptyHuInfo(x, y, z);
                    }

                }else{
                    this.v_hu_pane_show = false;
                }
            });
        },

        addClapFeature: function (feature) {
            var lonLatArr = getLonLatArray(feature.geometry.points);
            //console.log(lonLatArr);
            viewer.entities.add({
                id: 'identify-area',
                name: '单体化标识面',
                polygon: {
                    hierarchy: Cesium.Cartesian3.fromDegreesArray(lonLatArr),
                    material: Cesium.Color.YELLOW.withAlpha(0.6)   //new Cesium.Color(28, 140, 228, 0.6)//,
                    //classificationType: Cesium.ClassificationType.S3M_TILE
                }
            });
        },

        requestHuInfoByHid: function (hid, x, y, z) {
            console.log('--户查询', hid);
            this.v_hu_info.x = x;
            this.v_hu_info.y = y;
            this.v_hu_info.z = z;
            this.v_hu_info.xm = '';
            this.v_hu_info.dz = '';
            this.v_hu_info.lxdh = '';
            this.v_hu_info.jzmj = '';
            var _str_filter = "SSCID= '" + this.g_xqid + "' ";
            if (hid) {
                _str_filter += " and sfzh = '" + hid + "' ";
            }
            var _sqlParameter = {
                "getFeatureMode": "SQL",
                "datasetNames": ["p" + this.g_dsz_id + ":t02_hu"],
                "queryParameter": {
                    "attributeFilter": _str_filter,
                    "fields": ["id", "bh", "hzxm", "sfzh", "zu", "hao", "dz", "lxdh", "hjzmj", "fwjs", "fwcs", "sfkz", "zp"]
                },
                "maxFeatures": 10
            }
            http.requestIServer(_sqlParameter, (result) => {
                console.log('--户查询回调--', result.totalCount);
                if (result.features.length > 0) {
                    this.v_hu_pane_show = true;
					this.$emit('showPop')
                    var _feature = result.features[0];
                    this.v_hu_info.xm = _feature.fieldValues[2];
                    this.v_hu_info.dz = _feature.fieldValues[6];
                    this.v_hu_info.lxdh = _feature.fieldValues[7];
                    this.v_hu_info.jzmj = getTwoBitDotNumber(_feature.fieldValues[8]);
                }
            });
        },

        inputEmptyHuInfo: function (x, y, z) {
            this.v_hu_info = { xm: '-', dz: '-', lxdh: '-', jzmj: '-', x: x, y: y, z: z, rkList: [], dkList: [] };
        },

        closeHuPane: function () {
            this.v_hu_pane_show = false;
            viewer.entities.removeById('identify-area');
            cleanMarkerListByType('hdk');
        },

        requestRkList: function (hid) {
            //户成员
            var _str_filter = "SSCID= '" + this.g_xqid + "' ";
            if (hid) {
                _str_filter += " and hid = '" + hid + "' ";
            }
            var _sqlParameter = {
                "getFeatureMode": "SQL",
                "datasetNames": ["p" + this.g_dsz_id + ":t02_rk"],
                "queryParameter": {
                    "attributeFilter": _str_filter,
                    "fields": ["id", "xm", "xb", "mz", "sfzh", "csrq", "lxfs", "yhzgx"],
                    "orderby": 'csrq'
                },
                "maxFeatures": 20
            }
            http.requestIServerWithPage(_sqlParameter, 0, 19, (result) => {
                console.log('--户成员查询回调--', result.totalCount);
                if (result.features.length > 0) {
                    this.v_hu_info.rkList = [];
                    this.v_hu_pane_show = true;
                    this.$emit('showPop')
                    for (var i = 0; i < result.features.length; i++) {
                        var _feature = result.features[i];
                        var _person = {
                            xm: _feature.fieldValues[1],
                            xb: _feature.fieldValues[2],
                            csrq: _feature.fieldValues[5],
                            yhzgx: _feature.fieldValues[7]
                        };
                        if(_feature.fieldValues[7] == '户主'){//把户主排在第一位
                            this.v_hu_info.rkList.unshift(_person);
                        }else{
                            this.v_hu_info.rkList.push(_person);
                        }
                    }
                }
            });
        },

        requestDkList: function (hid) {
            //地块查询
            var _str_filter = "SSCID= '" + this.g_xqid + "' ";
            if (hid) {
                _str_filter += " and sfzh = '" + hid + "' ";
            }
            var _sqlParameter = {
                "getFeatureMode": "SQL",
                "datasetNames": ["p" + this.g_dsz_id + ":t02_dk"],
                "queryParameter": {
                    "attributeFilter": _str_filter,
                    "fields": ["ID", "QSRMC", "SFZH", "DKMC", "DKLX", "SCMJ", "M1", "M2", "M3", "M4", "M5", "M6", "M7", "M8", "M9", "M10", "M11", "M12", "X", "Y"],
                },
                "maxFeatures": 100
            }
            http.requestIServerWithPage(_sqlParameter, 0, 19, (result) => {
                console.log('--地块询回调--', result.totalCount);
                if (result.features.length > 0) {
                    this.v_hu_info.dkList = [];
                    for (var i = 0; i < result.features.length; i++) {
                        var _feature = result.features[i];
                        var _item = {
                            mc: _feature.fieldValues[3],
                            lx: _feature.fieldValues[4],
                            mj: _feature.fieldValues[5],
                            shape: getLonLatArray(_feature.geometry.points)
                        };
                        this.v_hu_info.dkList.push(_item);
                        var _config = { polygonFillColor: '#00ff00', alpha: 0.6, classificationType: 'S3M_TILE' };
                        addPolygonFunction('hdk', i, _item.mc, _item.shape, '', _config);
                    }
                }
            });
        },

        onFlyToDkPolygon(index) {
            console.log(index);
            var _entity = getMakerById('hdk-' + index);
            if (_entity) {
                viewer.flyTo(_entity, {
                    offset: {
                        heading: 0.0,
                        pitch: -1.5,
                        range: 100.0
                    }
                });
                this.focusEntity(_entity);
            }
        },

        focusEntity(entity) {
            if (g_focus_entity) {
                g_focus_entity.polygon.material = g_focus_entity.polygon.material2;
            }
            if (entity) {
                entity.polygon.material2 = entity.polygon.material;
                // entity.polygon.outline = true;
                entity.polygon.material = Cesium.Color.RED;
                // entity.polygon.outlineWidth = 2;
                g_focus_entity = entity;
            }
        },

        focusEntityBorder(index) {
            if (g_focus_zu_index > -1) {
                viewer.entities.removeById('focus-line');
            }
            if (index > -1) {
                var _config = { width: 5 };
                addLine('focus', 'line', '', this.v_zu_list[index].shape, null, _config);
                g_focus_zu_index = index;
            }
        },

        getZ() {
            if (g_map_type == 2) {
                return 10;
            }
            if (g_map_type == 3) {
                return 100;
            }
            return 0;
        }
    },
    destroyed() {
        if (g_interval_fly) {
            clearInterval(g_interval_fly);
            g_interval_fly = null;
        }
        this.v_hu_pane_show = false;
        this.hideAllHu();
        this.removeInputActionHu();
        this.v_if_check_house = false;
        if (g_handler) {
            g_handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
            g_handler.removeInputAction(Cesium.ScreenSpaceEventType.RIGHT_CLICK);
            g_handler = g_handler && g_handler.destroy();
        }
        if (g_handler_hu) {
            g_handler_hu.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_CLICK);
            g_handler_hu = g_handler_hu && g_handler_hu.destroy();
        }
        viewer.entities.removeById('identify-area');
    }
}
</script>

<style scoped lang="scss">
.cls-map-button-bar {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 1rem;
    /* border: 1px solid red; */
    z-index: 500;
}

.cls-pop-pane {
    font-size: 16px;
    height: 320px;
}

.cls-map-button {
    width: 60px;
    height: 60px;
    font-size: 13px;
    color: white;
    float: left;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 8px;
    text-align: center;
    margin: 0 3px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    span{
        font-size: .22rem;
    }
    
}

.cls-map-button:hover,
.cls-focus {
    background-color: #0b327f64;
    color: #fff;
    border: .02rem solid #79b7f7;
    box-sizing: border-box;
}

.cls-hu-title {
    text-align: center;
    font-weight: bold;
    color: white;
    background: #9e9e9e;
    height: 30px;
    line-height: 30px;
    padding-right: 10px;
    border-radius: 3px;
}

.cls-button-list {
    color: #eaf1ea7c;
    cursor: pointer;
}

.cls-hu-button {
    height: 20px;
    cursor: pointer;
    color: goldenrod;

}

.cls-hu-pane {
    height: 180px;
    width: 100%;
    /* border: 1px solid salmon; */
    overflow: auto;
}

.cls-hu-pane span {
    margin: 0 2px;
}

.cls-close-hu {
    float: right;
    color: red;
    cursor: pointer;
}
</style>